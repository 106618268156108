import React from 'react';
import { ButtonProps } from '../type';

const Button = ({ className, onClick, iconClassName, title, style, children }: ButtonProps): JSX.Element => {
    return (
        <button className={`pnt-btn ${className}`} style={style} title={title} onClick={onClick}>
            {iconClassName && <span className={iconClassName} />}
            {children}
        </button>
    );
};

export default Button;
