import React, { useContext, useEffect, useRef, useState } from 'react';
import Nouislider from 'nouislider-react';
import 'nouislider/distribute/nouislider.css';
import styled from 'styled-components';
import { Button, Col, Row } from 'reactstrap';
import { IoIosPause, IoIosPlay } from 'react-icons/all';
import { HeatMapDispatchContext, HeatMapStateContext } from '../index';
import { setPlay, setPlayTime, setRepeatSectionEnd, setRepeatSectionStart, setSliderUpdate } from '../heatMapReducer';
import { dateToFormat } from '../../../../util/common/util';
import useTranslation from '../../../../util/hooks/useTranslation';

const StyledNouislider = styled(Nouislider)`
    & .noUi-base {
        background-color: #3e66fb !important;
    }
    & .noUi-handle {
        cursor: pointer;
    }
    & .noUi-connect {
        background-color: #4b4b4b !important;
    }
`;
const CustomPlayButton = styled(Button)`
    border-radius: 18px;
    width: 33px;
    height: 35px;
    padding: 0;
    font-family: NotoSans;
    font-size: 15px;
`;
const CustomButton = styled(Button)`
    border-radius: 18px;
    width: 150px;
    height: 32px;
    padding: 0;
    font-family: NotoSans;
    font-size: 15px;
    font-weight: 500;
`;
export const PlayControllerBar = () => {
    const dispatch = useContext(HeatMapDispatchContext);
    const { play, playTime, startTime, endTime } = useContext(HeatMapStateContext);
    const slideThrottle = useRef();
    const tempPlayState = useRef(null);

    return (
        <StyledNouislider
            className="tracing-play-bar"
            animate={false}
            connect={[true, false]}
            step={1}
            start={[playTime]}
            range={{ min: startTime, max: endTime }}
            onSlide={(render, handle, value) => {
                if (play) {
                    tempPlayState.current = true;
                    dispatch(setPlay(false));
                } else {
                    tempPlayState.current = false;
                }
                dispatch(setSliderUpdate(value[0]));
                dispatch(setPlayTime(value[0]));
                if (slideThrottle.current) {
                    clearTimeout(slideThrottle.current);
                }
                slideThrottle.current = setTimeout(() => {
                    dispatch(setSliderUpdate(value[0]));
                    slideThrottle.current = null;
                    if (tempPlayState.current) {
                        dispatch(setPlay(true));
                    }
                }, 200);
            }}
        />
    );
};

const PlayController = () => {
    const t = useTranslation('Button');
    const dispatch = useContext(HeatMapDispatchContext);
    const { play: playState, playTime, startTime, endTime } = useContext(HeatMapStateContext);

    const playInterval = useRef();

    const play = () => {
        playInterval.current = setInterval(() => {
            dispatch(setPlayTime());
        }, 1000 / 64);
    };

    const pause = () => {
        clearInterval(playInterval.current);
    };

    const handlePlayClick = () => {
        dispatch(setPlay(!playState));
    };

    useEffect(() => {
        if (playState) {
            play();
        } else {
            pause();
        }
        return () => {
            pause();
        };
    }, [playState]);

    useEffect(() => {
        if (playState && playTime >= endTime) {
            dispatch(setPlay(false));
            dispatch(setPlayTime(startTime));
            pause();
        }
    }, [playTime]);

    const [repeatSectionStartButtonState, setRepeatSectionStartButtonState] = useState(true);
    const [repeatSectionEndButtonState, setRepeatSectionEndButtonState] = useState(false);

    const handleRepeatSectionStartTimeSetting = () => {
        dispatch(setRepeatSectionStart({ status: true, playTime }));
        setRepeatSectionStartButtonState(!repeatSectionStartButtonState);
        setRepeatSectionEndButtonState(!repeatSectionEndButtonState);
    };

    const handleRepeatSectionEndTimeSetting = () => {
        dispatch(setRepeatSectionEnd({ status: true, playTime }));
        setRepeatSectionEndButtonState(!repeatSectionEndButtonState);
    };

    const handleRepeatSectionSetting = () => {
        dispatch(setRepeatSectionStart({ status: false, playTime }));
        dispatch(setRepeatSectionEnd({ status: false, playTime }));
        setRepeatSectionStartButtonState(true);
        setRepeatSectionEndButtonState(false);
    };

    const handleShowAllClick = () => {
        dispatch(setPlayTime(endTime));
    };

    return (
        <Row className="play-body-custom" style={{ width: '100%', height: '20%' }}>
            <Col style={{ display: 'flex', flexDirection: 'column' }}>
                <Row className="mb-2 row-custom">
                    <PlayControllerBar />
                    <div className={'flx-sb mb-3'}>
                        <span className="play-time">{dateToFormat(startTime * 1000, 'START_MONTH_2')}</span>
                        <span className="play-time">{dateToFormat(playTime * 1000, 'START_MONTH_2')}</span>
                        <span className="play-time">{dateToFormat(endTime * 1000, 'START_MONTH_2')}</span>
                    </div>
                </Row>
                <Row className="row-custom">
                    <CustomButton className="btn-indigo-light" style={{ lineHeight: 0 }} onClick={handleShowAllClick}>
                        {t('Show All')}
                    </CustomButton>
                    <CustomPlayButton className=" btn-indigo-light" style={{ padding: '0' }} onClick={handlePlayClick}>
                        {!playState ? (
                            <IoIosPlay size="23" color="#fff" />
                        ) : (
                            <IoIosPause size="23" color="#fff" style={{ transform: 'scale(0.8)' }} />
                        )}
                    </CustomPlayButton>
                    {repeatSectionStartButtonState && (
                        <CustomButton className="btn-indigo-light" onClick={handleRepeatSectionStartTimeSetting}>
                            {t('Start of repeat section')}
                        </CustomButton>
                    )}
                    {repeatSectionEndButtonState && (
                        <CustomButton className=" btn-indigo-light" onClick={handleRepeatSectionEndTimeSetting}>
                            {t('Cancel repeat section')}
                        </CustomButton>
                    )}
                    {!repeatSectionStartButtonState && !repeatSectionEndButtonState && (
                        <CustomButton className="btn-indigo-light" onClick={handleRepeatSectionSetting}>
                            {t('End of repeat section')}
                        </CustomButton>
                    )}
                </Row>
            </Col>
        </Row>
    );
};

export default PlayController;
