import React, { useState } from 'react';

import Ionicon from 'react-ionicons';

import { Dropdown, DropdownToggle, DropdownMenu, Nav, NavItem } from 'reactstrap';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { faArrowDown } from '@fortawesome/fontawesome-free-solid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import city3 from '../../../../assets/utils/images/dropdown-header/city3.jpg';
import EVT_TYPE from '../../../../util/staticData/eventType';
import useAsync from '../../../../util/hooks/useAsync';
import { fetchAlertInfo } from '../../../../api/alert';
import TimeLine from '../../../Common/TimeLine';
// import LanguageSelectBox from './LanguageSelectBox';
import moment from 'moment';
import { isDanger } from '../../../../util/mappInfo';

const WrappedAlertBody = styled.div`
    margin-left: 1rem;
    height: 300px;
`;

const SeeMoreBtn = styled.button`
    width: 120px;
    height: 35px;
    color: white;
    background-color: #6e6c96;
    border-radius: 20px;
    outline: none;
    border: none;
    padding: 0.5rem;
`;

const getEvtType = evtTypeMap => {
    const evtTypes = [];
    for (let type in evtTypeMap) {
        evtTypes.push({
            type: type,
            name: evtTypeMap[type],
            typeClassName: isDanger(type) ? 'text-danger' : 'text-primary',
        });
    }
    return evtTypes;
};

const HeaderDots = () => {
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);
    const [alertList, setAlertList] = useState([]);
    const [expandDate, setExpandDate] = useState();
    const [clickBtn, setClickBtn] = useState(false);
    const [showArrow, setShowArrow] = useState(false);

    const eventType = getEvtType(EVT_TYPE);

    const { promise: getAlertList } = useAsync({
        promise: fetchAlertInfo,
        resolve: res => {
            if (clickBtn && res.rows.length > 0) {
                setShowArrow(true);
                setClickBtn(false);
            }

            setExpandDate(res.expandDate);
            setAlertList(alertList.concat(makeAlertList(res.rows)));
        },
        reject: error => {
            console.log(error);
        },
    });

    const makeAlertList = alertList => {
        return alertList.reduce((acc, alert) => {
            let evtType = eventType.find(type => type.type === alert.interfaceCommandType);
            if (evtType) {
                let time = moment(alert.regDate * 1000).format('HH:mm');
                let hour = moment(alert.regDate * 1000).format('HH');
                hour >= 12 && hour <= 23 ? (time = `${time} PM`) : (time = `${time} AM`);

                acc.push({
                    level: 'warning',
                    type: evtType.name,
                    typeClassName: evtType.typeClassName,
                    dateStr: time,
                    targetName: alert.targetName,
                    location: alert.floorName,
                    targetNum: alert.targetNum,
                    interfaceCommandType: alert.interfaceCommandType,
                    latLng: [alert.lat, alert.lng],
                    floorId: alert.floorId,
                });
            }
            return acc;
        }, []);
    };

    const handleClick = e => {
        e.stopPropagation();
        getAlertList({
            endDate: expandDate,
            isAlertCheckStatus: 'Y',
        });
        setClickBtn(true);
    };

    const handleScrollEvent = event => {
        if (event) {
            setShowArrow(false);
        }
    };

    const click = e => {
        setAlertList([]);
        if (!open) {
            getAlertList({
                isAlertCheckStatus: 'Y',
            });
        }
        setOpen(!open);
    };

    return (
        <div className="header-dots">
            <Dropdown isOpen={open} toggle={click}>
                <DropdownToggle className="p-0 border-0" color="link">
                    <div className="icon-wrapper icon-wrapper-alt rounded-circle">
                        <div className="icon-wrapper-bg bg-danger bg-d-1" />
                        <Ionicon beat={false} color="#ffffff" fontSize="23px" icon="md-notifications-outline" />
                    </div>
                </DropdownToggle>
                <DropdownMenu right className="dropdown-menu-xl rm-pointers">
                    <div className="dropdown-menu-header mb-0">
                        <div className="dropdown-menu-header-inner bg-deep-blue">
                            <div
                                className="menu-header-image opacity-1"
                                style={{
                                    backgroundImage: 'url(' + city3 + ')',
                                }}
                            />
                            <div className="menu-header-content text-dark">
                                <h5 className="menu-header-title">{t('Alert;Alerts')}</h5>
                            </div>
                        </div>
                    </div>
                    <WrappedAlertBody onScroll={handleScrollEvent}>
                        <TimeLine events={alertList} />
                        {showArrow && (
                            <FontAwesomeIcon
                                icon={faArrowDown}
                                style={{
                                    position: 'absolute',
                                    right: 20,
                                    bottom: 90,
                                    color: '#bdbebf',
                                    fontWeight: 'bold',
                                    fontSize: '1.2rem',
                                }}
                            />
                        )}
                    </WrappedAlertBody>
                    <Nav vertical>
                        <NavItem className="nav-item-divider" />
                        <NavItem className="nav-item-btn text-center">
                            <SeeMoreBtn className="btn-shadow btn-wide btn-pill" color="focus" onClick={handleClick}>
                                {t('Alert;See more')}
                            </SeeMoreBtn>
                        </NavItem>
                    </Nav>
                </DropdownMenu>
            </Dropdown>
            {/*<LanguageSelectBox />*/}
        </div>
    );
};

export default HeaderDots;
